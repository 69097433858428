import React from "react"
import {
  Box,
  Button,
  Link,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  Accordion,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
} from "@chakra-ui/react"
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink, navigate } from "gatsby"

const styles = {
  "menu-button": {
    display: {
      base: "flex",
      md: "flex",
      lg: "none",
    },
    fontSize: "16px",
    fontWeight: "normal",
    outline: "none",
    border: "none",
    bg: "transparent",
    mr: "24px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  "menu-list": {
    display: {
      base: "flex",
      md: "flex",
      lg: "none",
    },
    mt: "11px",
    padding: 0,
    minWidth: "350px",
    width: "100%",
    borderRadius: "0px",
    height: "100%",
    bg: "#F2F2F2",
  },
  accordion: {
    width: "100%",
    border: "none",
  },
  "accordion-item": {
    width: "100%",
    border: "none",
    py: 2,
  },
  "accordion-button": {
    px: "24px",
  },
  "accordion-box": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#1B263F",
  },
  "accordion-icon": {
    color: "#041424",
  },
  "accordion-panel": {
    px: "24px",
    py: "8px",
    bg: "background",
  },
  "close-icon": {
    color: "rgba(0, 0, 0, 0.54)",
  },
  "hamburger-icon": {
    color: "#041424",
  },
  "menu-item": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0.15px",
    height: "36px",
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: "#333333",
  },
  button: {
    width: "100%",
    height: "52px",
    bg: "#129459",
    padding: "12px",
    borderRadius: "4px",
    color: "#F0F0F0",
    fontSize: "16px",
    lineHeight: "175%",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  "login-button": {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "12px",
    height: "52px",
    bg: "#FFF",
    border: "1px solid",
    borderColor: "#D0D5DD",
    borderRadius: "4px",
    color: "#344054",
    fontSize: "16px",
    lineHeight: "24px",
    outline: "none",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    "&:hover,&:active,&:focus": {
      bg: "#FFF",
      outline: "none",
      color: "#344054",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us")

const MobileMenu = () => (
  <Menu>
    {({ isOpen }) => (
      <>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          rightIcon={
            isOpen ? (
              <CloseIcon w={5} h={4} sx={styles["close-icon"]} />
            ) : (
              <HamburgerIcon w={5} h={5} sx={styles["hamburger-icon"]} />
            )
          }
          sx={styles["menu-button"]}
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          _expanded={{ bg: "transparent" }}
        >
          Menu
        </MenuButton>
        <MenuList sx={styles["menu-list"]}>
          <Accordion
            defaultIndex={[0]}
            allowMultiple
            allowToggle
            sx={styles.accordion}
          >
            <AccordionItem sx={styles["accordion-item"]}>
              <AccordionButton sx={styles["accordion-button"]}>
                <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                  Why Avant-garde Health
                </Box>
                <AccordionIcon sx={styles["accordion-icon"]} />
              </AccordionButton>
              <AccordionPanel sx={styles["accordion-panel"]}>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/" sx={styles.link}>
                    Home
                  </Link>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem sx={styles["accordion-item"]}>
              <AccordionButton sx={styles["accordion-button"]}>
                <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                  Solutions and Services
                </Box>
                <AccordionIcon sx={styles["accordion-icon"]} />
              </AccordionButton>
              <AccordionPanel sx={styles["accordion-panel"]}>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/caremeasurement" sx={styles.link}>
                    Surgical Cost Management
                  </Link>
                </Box>

                <Box sx={styles["menu-item"]}>
                  <Link
                    as={GatsbyLink}
                    to="/surgical-coding-optimization"
                    sx={styles.link}
                  >
                    Surgical Coding Optimization
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/eras" sx={styles.link}>
                    Surgical Quality and Outcomes Optimization
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link
                    as={GatsbyLink}
                    to="/pacu-and-or-throughput"
                    sx={styles.link}
                  >
                    PACU and OR Throughput
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/bpci-advanced" sx={styles.link}>
                    BPCI Management
                  </Link>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem sx={styles["accordion-item"]}>
              <AccordionButton sx={styles["accordion-button"]}>
                <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                  Research
                </Box>
                <AccordionIcon sx={styles["accordion-icon"]} />
              </AccordionButton>
              <AccordionPanel sx={styles["accordion-panel"]}>
                <Box sx={styles["menu-item"]}>
                  <Link
                    as={GatsbyLink}
                    to="/best-surgeons-and-hospitals-research-all-stars"
                    sx={styles.link}
                  >
                    2024 Healthcare Research All-Stars
                  </Link>
                  <Link as={GatsbyLink} to="/publications" sx={styles.link}>
                    Research and Publications
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link
                    as={GatsbyLink}
                    to="/vbhc-research-group"
                    sx={styles.link}
                  >
                    VBHC Research Group
                  </Link>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem sx={styles["accordion-item"]}>
              <AccordionButton sx={styles["accordion-button"]}>
                <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                  Our Company
                </Box>
                <AccordionIcon sx={styles["accordion-icon"]} />
              </AccordionButton>
              <AccordionPanel sx={styles["accordion-panel"]}>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/about-us" sx={styles.link}>
                    About Us
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/news" sx={styles.link}>
                    News
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/blog" sx={styles.link}>
                    Blog
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/our-team" sx={styles.link}>
                    Our Team
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/careers" sx={styles.link}>
                    Careers
                  </Link>
                </Box>
                <Box sx={styles["menu-item"]}>
                  <Link as={GatsbyLink} to="/privacy-policy" sx={styles.link}>
                    Privacy Policy
                  </Link>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <Box px="24px" py={2}>
              <Button onClick={navigateToContactUs} sx={styles.button}>
                Let's Talk
              </Button>
            </Box>
            <Box px="24px" py={2}>
              <Link
                href="https://caremeasurement.com"
                isExternal
                sx={styles["login-button"]}
              >
                Login
              </Link>
            </Box>
          </Accordion>
        </MenuList>
      </>
    )}
  </Menu>
)

export default MobileMenu
