import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"
import Seo from "./seo"
import Navigation from "./navigation"
import Footer from "./footer"
import "@fontsource/inter"
import "@fontsource/roboto"
import "./variables.css"
import "./global.css"

const styles = {
  main: {
    width: "100%",
    height: "100%",
  },
}

class Template extends React.Component {
  render() {
    const { children, hideNavigation, title, description, image, keywords } = this.props

    return (
      <>
        <Seo title={title} description={description} image={image} keywords={keywords} />
        <Navigation hideMenu={hideNavigation} />
        <chakra.main sx={styles.main}>{children}</chakra.main>
        {!hideNavigation && <Footer />}
      </>
    )
  }
}

Template.propTypes = {
  hideNavigation: PropTypes.bool,
}

export default Template
