import React from "react"
import PropTypes from "prop-types"
import { Flex, Link } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"
import DesktopMenu from "./desktop-menu"
import MobileMenu from "./mobile-menu"

const styles = {
  nav: {
    height: "96px",
    bg: "white",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  logo: {
    minWidth: "194px",
    width: "194px",
    cursor: "pointer",
  },
}

const Navigation = ({ hideMenu }) => (
  <Flex as="nav" aria-label="Main" sx={styles.nav}>
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ml: { base: "24px", md: "24px", lg: "24px" },
      }}
    >
      <Link as={GatsbyLink} sx={styles.logo} to={"/"}>
        <StaticImage
          src="../../images/logos/header-logo.jpg"
          alt="Avant-garde Health logo"
          placeholder="blurred"
        />
      </Link>
    </Flex>
    {!hideMenu && <DesktopMenu />}
    {!hideMenu && <MobileMenu />}
  </Flex>
)

Navigation.propTypes = {
  hideMenu: PropTypes.bool,
}

export default Navigation
