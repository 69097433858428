import React from "react"
import { Flex, Button, Link } from "@chakra-ui/react"
import { Link as GatsbyLink, navigate } from "gatsby"
import DropdownMenu from "../dropdown-menu"

const styles = {
  menu: {
    display: {
      base: "none",
      md: "none",
      lg: "flex",
    },
    width: "735px",
    mr: { base: "24px", md: "24px", lg: "24px" },
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center",
  },
  link: {
    color: "#333333",
    fontSize: "14px",
  },
  "dropdown-menu": {
    "menu-button": {
      ml: "10px",
      fontSize: "14px",
    },
    "menu-list": {
      height: "auto",
    },
  },
  button: {
    padding: "12px",
    height: "52px",
    bg: "#129459",
    borderRadius: "4px",
    color: "#F0F0F0",
    fontSize: "16px",
    lineHeight: "24px",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  "login-button": {
    padding: "12px",
    height: "52px",
    bg: "#FFF",
    border: "1px solid",
    borderColor: "#D0D5DD",
    borderRadius: "4px",
    color: "#344054",
    fontSize: "16px",
    lineHeight: "24px",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#FFF",
      outline: "none",
      color: "#344054",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us")

const DesktopMenu = () => (
  <Flex sx={styles.menu}>
    <Link as={GatsbyLink} to="/" sx={styles.link}>
      Why Avant-garde Health
    </Link>
    <DropdownMenu
      styles={styles["dropdown-menu"]}
      title="Solutions and Services"
    >
      <Link as={GatsbyLink} to="/caremeasurement" sx={styles.link}>
        Surgical Cost Management
      </Link>
      <Link as={GatsbyLink} to="/surgical-coding-optimization" sx={styles.link}>
        Surgical Coding Optimization
      </Link>
      <Link as={GatsbyLink} to="/eras" sx={styles.link}>
        Surgical Quality and Outcomes Optimization
      </Link>
      <Link as={GatsbyLink} to="/pacu-and-or-throughput" sx={styles.link}>
        PACU and OR Throughput
      </Link>
      <Link as={GatsbyLink} to="/bpci-advanced" sx={styles.link}>
        BPCI Management
      </Link>
    </DropdownMenu>
    <DropdownMenu styles={styles["dropdown-menu"]} title="Research">
      <Link
        as={GatsbyLink}
        to="/best-surgeons-and-hospitals-research-all-stars"
        sx={styles.link}
      >
        2024 Healthcare Research All-Stars
      </Link>
      <Link as={GatsbyLink} to="/publications" sx={styles.link}>
        Research and Publications
      </Link>
      <Link as={GatsbyLink} to="/vbhc-research-group" sx={styles.link}>
        VBHC Research Group
      </Link>
    </DropdownMenu>
    <DropdownMenu styles={styles["dropdown-menu"]} title="Our Company">
      <Link as={GatsbyLink} to="/about-us" sx={styles.link}>
        About Us
      </Link>
      <Link as={GatsbyLink} to="/news" sx={styles.link}>
        News
      </Link>
      <Link as={GatsbyLink} to="/blog" sx={styles.link}>
        Blog
      </Link>
      <Link as={GatsbyLink} to="/our-team" sx={styles.link}>
        Our Team
      </Link>
      <Link as={GatsbyLink} to="/careers" sx={styles.link}>
        Careers
      </Link>
      <Link as={GatsbyLink} to="/privacy-policy" sx={styles.link}>
        Privacy Policy
      </Link>
    </DropdownMenu>
    <Link
      href="https://caremeasurement.com"
      isExternal
      sx={styles["login-button"]}
    >
      Login
    </Link>
    <Button onClick={navigateToContactUs} sx={styles.button}>
      Let's Talk
    </Button>
  </Flex>
)

export default DesktopMenu
